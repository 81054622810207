var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('table-data',{attrs:{"title":_vm.title,"table-headers":_vm.tableHeaders,"form-scheme":_vm.formScheme,"form-default-values":_vm.formDefaultValues,"resource":_vm.resource,"custom-opt":_vm.customOpt,"default-table-headers":['actions']},on:{"inputChange":_vm.handleChange,"currentItems":_vm.handleCurrentItems},scopedSlots:_vm._u([{key:"top-action-buttons",fn:function(){return undefined},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:("ma-2 " + (item.color === 'white' ? 'black--text' : 'white--text') + " "),style:("border: 1px solid #ebebeb !important;"),attrs:{"small":"","color":item.color}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"switch-center"},[_c('v-switch',{attrs:{"input-value":item.status,"hide-details":"","disabled":""}})],1)]}},{key:"item.creator",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.creator[0].name))]),_c('v-hover',{staticStyle:{"cursor":"pointer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticStyle:{"font-size":"12px","color":"#ccc"}},[_vm._v(" "+_vm._s(hover ? _vm.$helper.dateTimeFilter(item.createdAt) : _vm.$helper.dateFilter(item.createdAt))+" ")])]}}],null,true)})]}},{key:"item.editor",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.editor !== undefined && item.editor.length > 0 ? item.editor[0].name : '—'))]),_c('v-hover',{staticStyle:{"cursor":"pointer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(item.editor !== undefined && item.editor.length > 0)?_c('div',{staticStyle:{"font-size":"12px","color":"#ccc"}},[_vm._v(" "+_vm._s(hover ? _vm.$helper.dateTimeFilter(item.updatedAt) : _vm.$helper.dateFilter(item.updatedAt))+" ")]):_vm._e()]}}],null,true)})]}},{key:"item.tag_users_raw",fn:function(ref){
var item = ref.item;
return [(item.tag_users_raw.length > 0)?_c('div',{staticClass:"pb-1 pt-2"},[_vm._l(([].concat( item.tag_users_raw ).sort().splice(0, 5)),function(user,key){return _c('v-chip',{key:key,staticClass:"mr-1 mb-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(user.name)+" ")])}),(item.tag_users_raw.length > 5)?_c('v-chip',{attrs:{"small":""}},[_vm._v(" ...and other +"+_vm._s(item.tag_users_raw.length - 5)+" ")]):_vm._e()],2):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }