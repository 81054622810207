<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <table-data
          :title="title"
          :table-headers="tableHeaders"
          :form-scheme="formScheme"
          :form-default-values="formDefaultValues"
          :resource="resource"
          :custom-opt="customOpt"
          :default-table-headers="['actions']"
          @inputChange="handleChange"
          @currentItems="handleCurrentItems"
        >
          <template #top-action-buttons />
          <template #item.name="{ item }">
            <!--div
              :class="`${item.color} accent-2 mx-auto`"
              :style="`width: 30px; height: 30px; border: 1px solid #ebebeb !important;`"
            /-->
            <v-chip
              :class="`ma-2 ${item.color === 'white' ? 'black--text' : 'white--text'} `"
              small
              :color="item.color"
              :style="`border: 1px solid #ebebeb !important;`"
            >
              {{ item.name }}
            </v-chip>
          </template>

          <template
            #[`item.status`]="{ item }"
          >
            <div class="switch-center">
              <v-switch
                :input-value="item.status"
                hide-details
                disabled
              />
            </div>
          </template>

          <template #item.creator="{ item }">
            <div>{{ item.creator[0].name }}</div>
            <v-hover
              v-slot="{ hover }"
              style="cursor: pointer"
            >
              <div style="font-size: 12px; color: #ccc;">
                {{ hover ? $helper.dateTimeFilter(item.createdAt) : $helper.dateFilter(item.createdAt) }}
              </div>
            </v-hover>
          </template>

          <template #item.editor="{ item }">
            <div>{{ item.editor !== undefined && item.editor.length > 0 ? item.editor[0].name : '—' }}</div>
            <v-hover
              v-slot="{ hover }"
              style="cursor: pointer"
            >
              <div
                v-if="item.editor !== undefined && item.editor.length > 0"
                style="font-size: 12px; color: #ccc;"
              >
                {{ hover ? $helper.dateTimeFilter(item.updatedAt) : $helper.dateFilter(item.updatedAt) }}
              </div>
            </v-hover>
          </template>

          <template #item.tag_users_raw="{ item }">
            <div
              v-if="item.tag_users_raw.length > 0"
              class="pb-1 pt-2"
            >
              <v-chip
                v-for="(user, key) of [...item.tag_users_raw].sort().splice(0, 5)"
                :key="key"
                class="mr-1 mb-1"
                small
              >
                {{ user.name }}
              </v-chip>
              <v-chip
                v-if="item.tag_users_raw.length > 5"
                small
              >
                ...and other +{{ item.tag_users_raw.length - 5 }}
              </v-chip>
            </div>
          </template>
        </table-data>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Colors from './components/Colors'
export default {
  name: 'Tags',
  metaInfo() {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('tags.meta.title')} — %s`
    }
  },
  data: () => ({
    title: '',
    tableHeaders: [],
    formScheme: {},
    editedItem: {},
    resource: 'tags',
    syncLoading: false,
    customOpt: {
      itemsPerPage: 50
    },
    formDefaultValues: {}
  }),
  async mounted() {
    this.title = this.$t('tags.title')
    this.tableHeaders = [
      {
        text: this.$t('tags.table.tag'),
        align: 'start',
        value: 'name'
      },
      // {
      //   text: this.$t('tags.table.name'),
      //   align: 'start',
      //   value: 'name'
      // },
      // {
      //   text: this.$t('tags.table.color'),
      //   value: 'color',
      //   align: 'center',
      //   width: '100px'
      // },
      {
        text: this.$t('tags.table.users'),
        value: 'tag_users_raw'
      },
      {
        text: this.$t('tags.table.description'),
        value: 'description'
      },
      {
        text: this.$t('tags.table.status'),
        value: 'status',
        align: 'center',
        width: '100px'
      },
      {
        text: this.$t('tags.table.creator'),
        value: 'creator',
        align: 'center',
        width: '160px'
      },
      {
        text: this.$t('tags.table.editor'),
        value: 'editor',
        align: 'center',
        width: '160px'
      }
    ]
    this.formScheme = {
      name: {
        type: 'text',
        label: this.$t('tags.tableForm.name'),
        md: 12
      },
      color: {
        type: 'color',
        label: this.$t('tags.tableForm.color'),
        colors: ['red', 'orange', 'pink', 'blue', 'green', 'yellow', 'grey', 'black', 'white'],
        md: 12
      },
      tag_users: {
        type: 'autocomplete',
        label: this.$t('tags.tableForm.tag_users'),
        items: await this.$store.dispatch('users/getAllUsers'),
        multiple: true,
        md: 12
      },
      description: {
        type: 'textarea',
        label: this.$t('tags.tableForm.description'),
        md: 12,
        'auto-grow': true,
        rows: 1
      },
      is_default: {
        type: 'switch',
        label: this.$t('tags.tableForm.is_default'),
        md: 6
      },
      status: {
        type: 'switch',
        label: this.$t('tags.tableForm.status'),
        md: 6
      }
    }
  },
  methods: {
    handleChange (changes) {
      this.editedItem = changes
    },
    handleCurrentItems(items) {
      if (items.length > 0) {
        this.currentItems = items
      }
    }
  }
}
</script>

<style lang="scss">
.switch-center {
  display: flex;
  justify-content: center;
  margin-top: -15px;
}
</style>
